#override {
  margin-right: 0 !important;
}

#toggler {
  display: none;
  text-align: right;
  height: 46px;
  border-radius: 0;
  background-color: white;
  border-bottom: 1px solid #ddd;
}

.ui.menu .item.logo-nav {
  padding: 0 0.5em;
}
.ui.menu .item.logo-nav .text {
  display: none;
}

@media screen and (max-width: 768px) {
  .ui.menu .item.logo-nav {
    padding: 0.9375em 1.125em;
    padding-left: 0.5em;
  }
  .ui.menu .item.logo-nav img {
    max-height: 1.5em;
  }
  .ui.menu .item.logo-nav .text {
    display: inline;
  }

  #toggler {
    display: block;
  }

  #override.hidden,
  .ui.menu:not(.vertical) .menu.hidden {
    display: none;
  }
}
