.ui.form .prompt-form {
  &.huge {
    font-size: 1.25rem;
  }
  margin-bottom: 1rem;

  textarea {
    font-size: 1rem;
  }

  .ui.checkbox label {
    padding-left: 1.4em;
  }

  .ui.selection.dropdown {
    border: none;
    color: #1678c2;
    padding: 0;
    min-height: 1.8em;

    &:hover {
      color: #125d96;
    }

    &.active {
      color: #1678c2;
      border: none;
      box-shadow: none;
    }

    &:hover .menu,
    .menu {
      top: auto;
      border: none;
      // box-shadow: none;
      min-width: 5rem;
    }

    .menu > .item {
      padding: 0.6rem 0.5rem !important;
      min-width: 5rem;
      text-align: center;
    }

    > .dropdown.icon {
      top: 1em;
      width: 100%;
      right: none;
    }
  }

  .ui.disabled.dropdown {
    color: rgba(0, 0, 0, 0.87);
    opacity: 1;

    > .dropdown.icon {
      display: none;
    }
  }
}
