.wrapper {
  padding-top: 2.85714286em;
  min-height: 100vh;
}

a {
  text-decoration: underline;
}

body {
  background: url("./img/bg.jpg") center top;
}
