.ui.secondary.pointing.menu {
  margin-bottom: 1.5em;
  flex-wrap: wrap;
}
.ui.secondary.pointing.menu .item {
  padding: 0.875em 0.7em;
}
@media screen and (max-width: 450px) {
  .ui.secondary.pointing.menu .item {
    padding: 0.675em 0.9em;
  }
  .ui.secondary.pointing.menu .item > span {
    display: none;
  }
}
.ui.secondary.pointing.menu .item > i.icon {
  opacity: 0.7;
}
.ui.secondary.pointing.menu .item.active > i.icon {
  opacity: 1;
}
