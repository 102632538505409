.ui.form .prompt-form {
  margin-bottom: 1rem;
}
.ui.form .prompt-form.huge {
  font-size: 1.25rem;
}
.ui.form .prompt-form textarea {
  font-size: 1rem;
}
.ui.form .prompt-form .ui.checkbox label {
  padding-left: 1.4em;
}
.ui.form .prompt-form .ui.selection.dropdown {
  border: none;
  color: #1678c2;
  padding: 0;
  min-height: 1.8em;
}
.ui.form .prompt-form .ui.selection.dropdown:hover {
  color: #125d96;
}
.ui.form .prompt-form .ui.selection.dropdown.active {
  color: #1678c2;
  border: none;
  box-shadow: none;
}
.ui.form .prompt-form .ui.selection.dropdown:hover .menu,
.ui.form .prompt-form .ui.selection.dropdown .menu {
  top: auto;
  border: none;
  min-width: 5rem;
}
.ui.form .prompt-form .ui.selection.dropdown .menu > .item {
  padding: 0.6rem 0.5rem !important;
  min-width: 5rem;
  text-align: center;
}
.ui.form .prompt-form .ui.selection.dropdown > .dropdown.icon {
  top: 1em;
  width: 100%;
  right: none;
}
.ui.form .prompt-form .ui.disabled.dropdown {
  color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}
.ui.form .prompt-form .ui.disabled.dropdown > .dropdown.icon {
  display: none;
}
