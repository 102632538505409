.ui.secondary.pointing.menu {
  margin-bottom: 1.5em;
  flex-wrap: wrap;

  .item {
    padding: 0.875em 0.7em;
  }

  @media screen and (max-width: 450px) {
    .item {
      padding: 0.675em 0.9em;
    }

    .item > span {
      display: none;
    }
  }

  .item > i.icon {
    opacity: 0.7;
  }
  .item.active > i.icon {
    opacity: 1;
  }
}
